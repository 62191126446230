import React from "react";

import Layout from "../views/shared/layout/layout";
import Contact from "../components/common/contact";
import { SEO } from "../components/SEO/SEO";
import HeroContact from "../views/shared/heroContact/HeroContact";

const Contacto = () => {
  return (
    <Layout>
      <SEO
        title={"Contacto | Obelion"}
        description={"Contacto"}
        canonical={"contacto"}
      />
      <HeroContact >
        <h1 className={"a-hero-title a-hero-title--contacto"}>Contacto</h1>
      </HeroContact>
      <div className={"o-contact container"}>
        <div className={"m-card m-card--contact"}>
          <Contact />
        </div>
      </div>
    </Layout>
  );
};

export default Contacto;