import "./hero-contact.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import background from "../../../assets/images/hero-tarifas.jpg";

const HeroContact: React.FC = (props: PropsWithChildren<any>) => {
  return (
    <>
      <section>
        <div className="hero-background">
          <svg width="0" height="0">
            <defs>
              <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                <path d="M 0,0 H 1 V 0.6 C 1,0.6 0.50714872,0.98351558 0.35,0.98496487 0.22689092,0.98610023 0.04614615,0.63365878 0,0.6 Z" />
              </clipPath>
              <clipPath id="myClipDevice" clipPathUnits="objectBoundingBox">
                <path d="M 0,0 H 1 V 0.75 C 1,0.75 0.50714872,0.98303314 0.35,0.98448244 0.22689092,0.98561779 0.04614615,0.88365878 0,0.85 Z" />
              </clipPath>
            </defs>
          </svg>
          <img src={background} width={600} height={300} className={"cut"} />
        </div>
        <div className={"o-hero-contact"}>
          <div className={"m-hero-content"}>{props.children}</div>
        </div>
      </section>
    </>
  );
};

export default HeroContact;
